export let langArr = {};
langArr.en = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.sw = {
'loading': 'Inapakia',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.zu = {
'loading': 'Iyalayisha',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.xh = {
'loading': 'Iyalowuda',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.af = {
'loading': 'Laai',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.so = {
'loading': 'Ea loada',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.tn = {
'loading': 'E a laisa',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.ar = {
'loading': 'جاري التحميل',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.bg = {
'loading': 'Зареждане',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.de = {
'loading': 'Lädt',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.el = {
'loading': 'Φόρτωση',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.es = {
'loading': 'Cargando',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.fr = {
'loading': 'Chargement',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.he = {
'loading': 'טוען',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.hr = {
'loading': 'Učitavanje',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.hu = {
'loading': 'Betöltés',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.it = {
'loading': 'Caricamento',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.mk = {
'loading': 'Се вчитува',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.pl = {
'loading': 'Ładowanie',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.pt = {
'loading': 'Carregando',
'hotkeys': 'Teclas de atalho',
'hotkeys_enabled': 'Teclas de atalho ativadas',
'enable_hotkeys_checkbox': 'Habilitar teclas de atalho abaixo marcando esta caixa.',
'make_bet': 'Fazer uma aposta',
'double_bet': 'Dobrar o valor da aposta',
'halve_bet': 'Reduzir o valor da aposta pela metade',
'min_bet': 'Valor mínimo da aposta',
'cashout_key': 'Saque',
'walk_next_line': 'Andar para a próxima linha',
'max': 'Máximo',
'easy': 'Fácil',
'medium': 'Médio',
'hard': 'Difícil',
'daredevil': 'Audacioso',
'max_profit': 'Lucro Máximo',
'manual': 'Manual',
'auto': 'Automático',
'bet_amount': 'Valor da aposta',
'difficulty': 'Dificuldade',
'payout_on_win': 'Pagamento em caso de vitória',
'number_of_bets': 'Número de apostas',
'on_win': 'Em caso de vitória',
'on_loss': 'Em caso de perda',
'stop_on_profit': 'Parar ao atingir lucro',
'stop_on_loss': 'Parar ao atingir perda',
'reset': 'Reiniciar',
'increase_by': 'Aumentar por',
'cashout': 'SAQUE',
'start_game': 'APOSTA',
'start_auto_game': 'INICIAR',
'difficulity_info': 'Informações sobre a dificuldade',
'chance_collision': 'Chance de colisão por jogo',
'evry_25_lane': 'A cada 25 linhas',
'insufficiend_funds': 'Fundos insuficientes',
'cashout_inactivity': 'Saque automático após inatividade',
'time_running_out': 'O tempo está se esgotando! Jogue agora ou seu bilhete será retirado automaticamente em 30 segundos',
'there_was_error': 'OCORREU UM ERRO, TENTE NOVAMENTE OU RECARREGUE',
'detailed_game_rules': 'Regras detalhadas do jogo',
'welcome': 'Bem-vindo ao Raccoon Rush - um jogo novo e emocionante!',
'general_rules': 'REGRAS GERAIS',
'general_rules_p1': '• O multiplicador começa em 1 e vai até',
'general_rules_p2': '• Os ganhos são calculados multiplicando o valor da aposta pelo multiplicador no momento do evento de saque',
'general_rules_p3': '• Se o pagamento máximo for alcançado, a aposta será automaticamente sacada no valor máximo de ganho',
'progressive_jackpot': 'JACKPOT PROGRESSIVO',
'progressive_jackpot_p1': '1% de cada aposta é destinado a um fundo de jackpot progressivo.',
'progressive_jackpot_p2': 'O jackpot é concedido usando uma mecânica "Mistério" ou "must-hit-by". O valor de cada jackpot "must-hit-by" é determinado imediatamente após o jackpot anterior ser ganho por um gerador de números aleatórios e armazenado como um valor criptografado no banco de dados remoto. O valor é criptografado, e assim, oculto e desconhecido.',
'progressive_jackpot_p3': 'Uma vez que o valor "Must-Hit-By" seja alcançado, o primeiro jogador a fazer uma aposta depois disso terá a chance de ganhar o jackpot. Um símbolo de jackpot aparecerá em uma linha aleatória, e o jackpot será ganho se o jogador alcançar a linha com o símbolo. Se o jogador não alcançar a linha com o símbolo do jackpot devido ao saque ou colisão, o próximo jogador a fazer uma aposta verá o símbolo do jackpot aparecer e terá a chance de ganhar. Esse processo se repete até que o jackpot seja ganho.',
'showdown': 'CONFRONTO',
'showdown_p1': 'Após o jogador sacar, um quadrado vermelho será exibido no local onde a colisão teria ocorrido se o jogador tivesse continuado pulando as linhas.',
'return_to_player': 'RETORNO AO JOGADOR',
'return_to_player_p1': 'O retorno ao jogador (RTP), incluindo retornos do jogo e jackpots, é definido em 98%, dos quais 97% vêm diretamente do RTP do jogo, com 1% adicional vindo do jackpot.',
'return_to_player_p2': 'As porcentagens de RTP são monitoradas e verificadas continuamente por terceiros independentes.',
'disconnection_policy': 'POLÍTICA DE DESCONEXÃO',
'disconnection_policy_p1': 'Se a conexão for perdida após uma aposta ser feita, a aposta não será cancelada. Se a inatividade do jogador atingir',
'disconnection_policy_p2': 'sua aposta será automaticamente sacada e os créditos serão aplicados à sua conta.',
'network_latency': 'LATÊNCIA DE REDE',
'network_latency_p1': 'A latência de rede é uma parte inevitável da conectividade com a internet. Consideramos todas as apostas e saques válidos apenas no momento em que chegam ao nosso servidor, independentemente da duração da latência de rede.',
'sound': 'SOM',
'sound_p1': 'O Raccoon Rush foi projetado com belas músicas de fundo e efeitos sonoros em mente. Caso deseje desativar a música e/ou efeitos sonoros, você pode fazer isso através dos ícones para efeitos (1) e som (2) localizados no canto inferior direito da tela.',
'error_handling': 'MANUSEIO DE ERROS',
'error_handling_p1': 'In caso de erro, nossa equipe de suporte tentará resolver o problema o mais rápido possível e retomar o jogo normal. Em caso de problema com o jogo em si, todas as apostas serão canceladas e o valor apostado será devolvido aos jogadores.',
'max_payout': 'O pagamento máximo é',
'max_bet_is': 'A aposta máxima é',
'min_bet_is': 'A aposta mínima é',
'per_bet': 'por aposta.',
'malfunction_voids': 'Falhas anulam todos os pagamentos e jogadas.',
'attention_jackpot': 'Atenção, Jackpot na linha',
'lane': 'linha',
'congratulations': 'parabéns',
'you_won': 'você ganhou',
'balance': 'saldo',
'time': 'tempo',
'jackpot': 'jackpot',
'escaped': 'ESCAPOU',
'max_win': 'GANHO MÁXIMO',
'check_internet': 'Por favor, verifique sua conexão com a internet',
'websocket_disconected': 'Websocket desconectado',
'websocket_connection_error': 'Erro de conexão Websocket!',
'session_expired': 'Sessão expirada, por favor recarregue',
'remaining_bets': 'Apostas restantes',
'stop_autoplay': 'PARAR',
'autoplay_and_autocashout': 'AUTOPLAY E AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'O jogador pode ativar a opção de autoplay onde a aposta é colocada automaticamente para cada nova rodada, usando o valor da aposta anterior.',
'autoplay_and_autocashout_p2': 'Um multiplicador de autocashout pode ser selecionado na tela do jogo, e quando definido, o jogo automaticamente sacará se o jogador não for atingido por um carro antes de atingir o multiplicador selecionado.',
'autoplay_button_label': 'Para começar, escolha uma linha.',
'min_bet_amount_is': 'O valor mínimo da aposta é',
'bonus_rounds': 'rodadas bônus',
'bonus_rounds_p1': 'Um jogador pode ser premiado aleatoriamente com rodadas bônus (ou seja, apostas grátis). Um gráfico pop-up aparecerá na tela notificando o jogador sobre as rodadas bônus premiadas, o número de apostas e o valor por aposta.',
'bonus_rounds_p2': 'Uma vez premiado, o jogador pode escolher quando iniciar a rodada bônus. Uma vez iniciada a rodada bônus, uma aposta automática com o valor especificado é feita, e o jogador pode sacar a qualquer momento.',
'bonus_rounds_p3': 'As rodadas bônus podem ter um limite de tempo, após o qual se tornam inválidas e não utilizáveis. O período de validade, se houver, é exibido abaixo da rodada bônus.',
'bonus_rounds_p4': 'A inatividade do jogador pode resultar no cancelamento de uma rodada bônus ativa. Nesse caso, o número de rodadas bônus diminui e o saldo do jogador permanece o mesmo.',
'valid_until': 'válido até',
'bonus': 'bônus',
'rounds': 'rodadas',
'win_eur': 'ganhar (EUR)',
'complete_your_bet': 'Complete sua aposta primeiro',
'complete_auto_play': 'Complete seu autoplay primeiro',
'yes': 'sim',
'no': 'não',
'play_bonus_rounds': 'Jogar rodadas bônus?',
'bet_history': 'Histórico de apostas',
'jackpot_history': 'Histórico de jackpot',
'ticket_id': 'ID do bilhete',
'created': 'Hora de criação',
'currency': 'moeda',
'bet': 'aposta',
'bet_eur': 'aposta (EUR)',
'lanes': 'linhas',
'cars': 'carros',
'multiplier': 'multiplicador',
'win': 'ganhar',
'no_betting_history': 'Nenhum histórico de apostas encontrado. Faça uma aposta, e ela aparecerá aqui.',
'no_jackpot_history': 'Nenhum histórico de jackpot disponível.',
'date': 'data',
'user': 'usuário'
};
langArr.br = {
'loading': 'A carregar',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.ro = {
'loading': 'Se încarcă',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.ru = {
'loading': 'Загрузка',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.sq = {
'loading': 'Po ngarkohet',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.sv = {
'loading': 'Laddar',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.tr = {
'loading': 'Yükleniyor',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.uk = {
'loading': 'Завантаження',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.sr = {
'loading': 'Učitavanje',
'hotkeys': 'Prečice',
'hotkeys_enabled': 'Prečice aktivne',
'enable_hotkeys_checkbox': 'Omogući prečice aktiviranjem ove opcije.',
'make_bet': 'Uplati tiket',
'double_bet': 'Dupliraj ulog',
'halve_bet': 'Prepolovi ulog',
'min_bet': 'Minimalni ulog',
'cashout_key': 'Isplata',
'walk_next_line': 'Pređi u sledeću traku',
'max': 'Max',
'easy': 'Lako',
'medium': 'Osrednje',
'hard': 'Teško',
'daredevil': 'Izazovno',
'max_profit': 'Max Profit',
'manual': 'Ručno',
'auto': 'Automatski',
'bet_amount': 'Iznos opklade',
'difficulty': 'Težina',
'payout_on_win': 'Isplata po dobitku',
'number_of_bets': 'Broj opklada',
'on_win': 'po dobitku',
'on_loss': 'po gubitku',
'stop_on_profit': 'Prekini na profitu',
'stop_on_loss': 'Prekini na gubitku',
'reset': 'Reset',
'increase_by': 'Povećaj za',
'cashout': 'ISPLATI TIKET',
'start_game': 'UPLATI TIKET',
'start_auto_game': 'START',
'difficulity_info': 'Težina igre',
'chance_collision': 'Šansa za sudar po igri',
'evry_25_lane': 'za svih 25 traka',
'insufficiend_funds': 'Nemate dovoljno novca',
'cashout_inactivity': 'Isplata usled neaktivnosti',
'time_running_out': 'Vreme ističe! Igraj odmah ili će tvoj tiket biti isplaćen u narednih 30 sekundi',
'there_was_error': 'Dogodila se greška! Učitaj igru ponovo.',
'detailed_game_rules': 'Detaljna Pravila Igre',
'welcome': 'Dobrodošli u Raccoon Rush - novu i uzbudljivu igru!',
'general_rules': 'OPŠTA PRAVILA',
'general_rules_p1': '• Množilac počinje od 1 i ide do ',
'general_rules_p2': '• Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na dugme ISPLATI TIKET',
'general_rules_p3': '• Ako je dostignut maksimalni dobitak opklada se automatski unovčava',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'progressive_jackpot_p1': '1% svakog uloga se izdvaja u fond za progresivni džekpot.',
'progressive_jackpot_p2': 'Džekpot se dodeljuje putem mehanike "Mistery" ili "must-hit-by". Vrednost svakog "must-hit-by" džekpota se određuje odmah nakon što prethodni džekpot bude osvojen, koristeći generator slučajnih brojeva. Ta vrednost se čuva kao šifrovani broj u udaljenoj bazi podataka, što znači da je skrivena i nepoznata.',
'progressive_jackpot_p3': 'Kada se dostigne vrednost "must-hit-by", prvi igrač koji uloži nakon toga imaće priliku da osvoji džekpot. Simbol džekpota će se pojaviti na nasumičnoj traci, a džekpot se osvaja ako igrač stigne do trake sa džekpot simbolom. Ako igrač ne stigne do trake sa simbolom džekpota zbog isplate ili sudara, sledeći igrač koji uloži videće simbol džekpota i imati priliku da ga osvoji. Ovaj proces se ponavlja dok džekpot ne bude osvojen.',
'showdown': 'OTKRIVANJE',
'showdown_p1': 'Nakon izvršene isplate, crveni kvadrat će biti prikazan na poziciji gde bi se sudar dogodio da je igrač nastavio sa igrom.',
'return_to_player': 'POVRAĆAJ IGRAČU',
'return_to_player_p1': 'Povraćaj igraču (RTP), uključujući vraćanje igara i džekpota, postavljen je na 98% od čega 97% dolazi direktno iz igre, a dodatnih 1.00% iz džekpota.',
'return_to_player_p2': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'disconnection_policy': 'POLITIKA POVEZIVANJA',
'disconnection_policy_p1': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako je igrač neaktivan više od ',
'disconnection_policy_p2': 's opklada će biti automatski isplaćena i krediti će biti primenjeni na vaš račun.',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_p1': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'sound': 'ZVUK',
'sound_p1': 'Raccoon Rush je dizajniran sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da onemogućite muziku i/ili zvučne efekte, to možete učiniti putem ikona za kontrolu zvučnih efekata (1) i zvuka (2) koje se nalaze u donjem desnom uglu ekrana.',
'error_handling': 'U SLUČAJU GREŠKE',
'error_handling_p1': 'U slučaju greške naš tim za podršku će pokušati rešiti problem što je pre moguće. U slučaju problema sa samom igrom, sve opklade će biti poništene, a iznos uloga vraćen igračima.',
'max_payout': 'Maksimalna isplata je ',
'max_bet_is': 'Maksimalni ulog je ',
'min_bet_is': 'Minimalni ulog je ',
'per_bet': 'po opkladi.',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'attention_jackpot': 'Pažnja, Džekpot na traci ',
'lane': 'traka',
'congratulations': 'bravo',
'you_won': 'osvojio si',
'balance': 'balans',
'time': 'vreme',
'jackpot': 'džekpot',
'escaped': 'POBEGAO',
'max_win': 'MAX DOBITAK',
'check_internet': 'Molimo proverite internet konekciju',
'websocket_disconected': 'Websocket diskonektovan',
'websocket_connection_error': 'Websocket greska u konekciji !',
'session_expired': 'Sesija je istekla',
'remaining_bets': 'Preostale opklade',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTO-IGRA I AUTO-ISPLATA',
'autoplay_and_autocashout_p1': 'Igrač može aktivirati opciju AUTO-IGRA gde se opklada automatski stavlja za svaku novu rundu koristeći iznos opklade iz prethodne runde.',
'autoplay_and_autocashout_p2': 'Odabir množioca za automatsku isplatu se vrši sa ekrana igre. Kada je množilac podešen, igra će automatski izvršiti isplatu ako igrača ne udari automobil pre nego što dostigne izabrani množilac.',
'autoplay_button_label': 'Za pocetak, izaberi traku.',
'min_bet_amount_is': 'Min iznos opklade je',
'bonus_rounds': 'bonus runde',
'bonus_rounds_p1': 'Igrač može biti nasumično nagrađen besplatnim opkladama u vidu bonus rundi. Na ekranu će se pojaviti skočna slika koja obaveštava igrača o dodeljenim besplatnim rundama, broju rundi i iznosu po opkladi.',
'bonus_rounds_p2': 'Nakon što se dodele bonus runde, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku.',
'bonus_rounds_p3': 'Besplatne opklade mogu imati rok važenja nakon čega ističu i ne mogu se koristiti. Rok važenja, ako postoji, je prikazan ispod bonus rundi.',
'bonus_rounds_p4': 'Neaktivnost igrača moze prouzrokovati otkazivanje aktivne bonus runde. U tom slučaju, broj bonus rundi se smanjuje a balans igrača ostaje nepromenjen.',
'valid_until': 'važi do',
'bonus': 'bonus',
'rounds': 'runda',
'win_eur': 'dobitak EUR',
'complete_your_bet': 'Prvo završite vašu opkladu',
'complete_auto_play': 'Prvo završite vašu auto-igru',
'yes': 'da',
'no': 'ne',
'play_bonus_rounds': 'Igraj Bonus runde?',
'bet_history': 'Istorija opklada',
'jackpot_history': 'Istorija džekpota',
'ticket_id': 'Ticket ID',
'created': 'vreme kreiranja',
'currency': 'valuta',
'bet': 'ulog',
'bet_eur': 'ulog EUR',
'lanes': 'trake',
'cars': 'automobili',
'multiplier': 'množitelj',
'win': 'dobitak',
'no_betting_history': 'Istorija opklada nije pronađena. Napravite opkladu i ona će se pojaviti ovde.',
'no_jackpot_history': 'Nema dostupne istorije džekpota.',
'date': 'datum',
'user': 'igrač'
};
langArr.rs = {
'loading': 'Učitavanje',
'hotkeys': 'Prečice',
'hotkeys_enabled': 'Prečice aktivne',
'enable_hotkeys_checkbox': 'Omogući prečice aktiviranjem ove opcije.',
'make_bet': 'Uplati tiket',
'double_bet': 'Dupliraj ulog',
'halve_bet': 'Prepolovi ulog',
'min_bet': 'Minimalni ulog',
'cashout_key': 'Isplata',
'walk_next_line': 'Pređi u sledeću traku',
'max': 'Max',
'easy': 'Lako',
'medium': 'Osrednje',
'hard': 'Teško',
'daredevil': 'Izazovno',
'max_profit': 'Max Profit',
'manual': 'Ručno',
'auto': 'Automatski',
'bet_amount': 'Iznos opklade',
'difficulty': 'Težina',
'payout_on_win': 'Isplata po dobitku',
'number_of_bets': 'Broj opklada',
'on_win': 'po dobitku',
'on_loss': 'po gubitku',
'stop_on_profit': 'Prekini na profitu',
'stop_on_loss': 'Prekini na gubitku',
'reset': 'Reset',
'increase_by': 'Povećaj za',
'cashout': 'ISPLATI TIKET',
'start_game': 'UPLATI TIKET',
'start_auto_game': 'START',
'difficulity_info': 'Težina igre',
'chance_collision': 'Šansa za sudar po igri',
'evry_25_lane': 'za svih 25 traka',
'insufficiend_funds': 'Nemate dovoljno novca',
'cashout_inactivity': 'Isplata usled neaktivnosti',
'time_running_out': 'Vreme ističe! Igraj odmah ili će tvoj tiket biti isplaćen u narednih 30 sekundi',
'there_was_error': 'Dogodila se greška! Učitaj igru ponovo.',
'detailed_game_rules': 'Detaljna Pravila Igre',
'welcome': 'Dobrodošli u Raccoon Rush - novu i uzbudljivu igru!',
'general_rules': 'OPŠTA PRAVILA',
'general_rules_p1': '• Množilac počinje od 1 i ide do ',
'general_rules_p2': '• Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na dugme ISPLATI TIKET',
'general_rules_p3': '• Ako je dostignut maksimalni dobitak opklada se automatski unovčava',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'progressive_jackpot_p1': '1% svakog uloga se izdvaja u fond za progresivni džekpot.',
'progressive_jackpot_p2': 'Džekpot se dodeljuje putem mehanike "Mistery" ili "must-hit-by". Vrednost svakog "must-hit-by" džekpota se određuje odmah nakon što prethodni džekpot bude osvojen, koristeći generator slučajnih brojeva. Ta vrednost se čuva kao šifrovani broj u udaljenoj bazi podataka, što znači da je skrivena i nepoznata.',
'progressive_jackpot_p3': 'Kada se dostigne vrednost "must-hit-by", prvi igrač koji uloži nakon toga imaće priliku da osvoji džekpot. Simbol džekpota će se pojaviti na nasumičnoj traci, a džekpot se osvaja ako igrač stigne do trake sa džekpot simbolom. Ako igrač ne stigne do trake sa simbolom džekpota zbog isplate ili sudara, sledeći igrač koji uloži videće simbol džekpota i imati priliku da ga osvoji. Ovaj proces se ponavlja dok džekpot ne bude osvojen.',
'showdown': 'OTKRIVANJE',
'showdown_p1': 'Nakon izvršene isplate, crveni kvadrat će biti prikazan na poziciji gde bi se sudar dogodio da je igrač nastavio sa igrom.',
'return_to_player': 'POVRAĆAJ IGRAČU',
'return_to_player_p1': 'Povraćaj igraču (RTP), uključujući vraćanje igara i džekpota, postavljen je na 98% od čega 97% dolazi direktno iz igre, a dodatnih 1.00% iz džekpota.',
'return_to_player_p2': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'disconnection_policy': 'POLITIKA POVEZIVANJA',
'disconnection_policy_p1': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako je igrač neaktivan više od ',
'disconnection_policy_p2': 's opklada će biti automatski isplaćena i krediti će biti primenjeni na vaš račun.',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_p1': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'sound': 'ZVUK',
'sound_p1': 'Raccoon Rush je dizajniran sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da onemogućite muziku i/ili zvučne efekte, to možete učiniti putem ikona za kontrolu zvučnih efekata (1) i zvuka (2) koje se nalaze u donjem desnom uglu ekrana.',
'error_handling': 'U SLUČAJU GREŠKE',
'error_handling_p1': 'U slučaju greške naš tim za podršku će pokušati rešiti problem što je pre moguće. U slučaju problema sa samom igrom, sve opklade će biti poništene, a iznos uloga vraćen igračima.',
'max_payout': 'Maksimalna isplata je ',
'max_bet_is': 'Maksimalni ulog je ',
'min_bet_is': 'Minimalni ulog je ',
'per_bet': 'po opkladi.',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'attention_jackpot': 'Pažnja, Džekpot na traci ',
'lane': 'traka',
'congratulations': 'bravo',
'you_won': 'osvojio si',
'balance': 'balans',
'time': 'vreme',
'jackpot': 'džekpot',
'escaped': 'POBEGAO',
'max_win': 'MAX DOBITAK',
'check_internet': 'Molimo proverite internet konekciju',
'websocket_disconected': 'Websocket diskonektovan',
'websocket_connection_error': 'Websocket greska u konekciji !',
'session_expired': 'Sesija je istekla',
'remaining_bets': 'Preostale opklade',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTO-IGRA I AUTO-ISPLATA',
'autoplay_and_autocashout_p1': 'Igrač može aktivirati opciju AUTO-IGRA gde se opklada automatski stavlja za svaku novu rundu koristeći iznos opklade iz prethodne runde.',
'autoplay_and_autocashout_p2': 'Odabir množioca za automatsku isplatu se vrši sa ekrana igre. Kada je množilac podešen, igra će automatski izvršiti isplatu ako igrača ne udari automobil pre nego što dostigne izabrani množilac.',
'autoplay_button_label': 'Za pocetak, izaberi traku.',
'min_bet_amount_is': 'Min iznos opklade je',
'bonus_rounds': 'bonus runde',
'bonus_rounds_p1': 'Igrač može biti nasumično nagrađen besplatnim opkladama u vidu bonus rundi. Na ekranu će se pojaviti skočna slika koja obaveštava igrača o dodeljenim besplatnim rundama, broju rundi i iznosu po opkladi.',
'bonus_rounds_p2': 'Nakon što se dodele bonus runde, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku.',
'bonus_rounds_p3': 'Besplatne opklade mogu imati rok važenja nakon čega ističu i ne mogu se koristiti. Rok važenja, ako postoji, je prikazan ispod bonus rundi.',
'bonus_rounds_p4': 'Neaktivnost igrača moze prouzrokovati otkazivanje aktivne bonus runde. U tom slučaju, broj bonus rundi se smanjuje a balans igrača ostaje nepromenjen.',
'valid_until': 'važi do',
'bonus': 'bonus',
'rounds': 'runda',
'win_eur': 'dobitak EUR',
'complete_your_bet': 'Prvo završite vašu opkladu',
'complete_auto_play': 'Prvo završite vašu auto-igru',
'yes': 'da',
'no': 'ne',
'play_bonus_rounds': 'Igraj Bonus runde?',
'bet_history': 'Istorija opklada',
'jackpot_history': 'Istorija džekpota',
'ticket_id': 'Ticket ID',
'created': 'vreme kreiranja',
'currency': 'valuta',
'bet': 'ulog',
'bet_eur': 'ulog EUR',
'lanes': 'trake',
'cars': 'automobili',
'multiplier': 'množitelj',
'win': 'dobitak',
'no_betting_history': 'Istorija opklada nije pronađena. Napravite opkladu i ona će se pojaviti ovde.',
'no_jackpot_history': 'Nema dostupne istorije džekpota.',
'date': 'datum',
'user': 'igrač'
};
langArr.zh = {
'loading': '加载中',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.hi = {
'loading': 'लोड हो रहा है',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.cs = {
'loading': 'Načítání',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.id = {
'loading': 'Memuat',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.th = {
'loading': 'กำลังโหลด',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.tl = {
'loading': 'Naglo-load',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.xe = {
'loading': 'Loading',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.da = {
'loading': 'Indlæser',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
langArr.nl = {
'loading': 'Bezig met laden',
'hotkeys': 'Hotkeys',
'hotkeys_enabled': 'Hotkeys Enabled',
'enable_hotkeys_checkbox': 'Enable Hotkeys shown below by checking this box.',
'make_bet': 'Make a bet',
'double_bet': 'Double a bet amount',
'halve_bet': 'Halve a bet amount',
'min_bet': 'Minimal bet amount',
'cashout_key': 'Cashout',
'walk_next_line': 'Walk to next line',
'max': 'Max',
'easy': 'Easy',
'medium': 'Medium',
'hard': 'Hard',
'daredevil': 'Daredevil',
'max_profit': 'Max Profit',
'manual': 'Manual',
'auto': 'Auto',
'bet_amount': 'Bet amount',
'difficulty': 'Difficulty',
'payout_on_win': 'Payout on Win',
'number_of_bets': 'Number of Bets',
'on_win': 'On Win',
'on_loss': 'On Loss',
'stop_on_profit': 'Stop on Profit',
'stop_on_loss': 'Stop on Loss',
'reset': 'Reset',
'increase_by': 'Increase By',
'cashout': 'CASHOUT',
'start_game': 'BET',
'start_auto_game': 'START',
'difficulity_info': 'Difficulty Info',
'chance_collision': 'Chance of collision per game',
'evry_25_lane': 'for every 25 lanes',
'insufficiend_funds': 'Insufficiend Funds',
'cashout_inactivity': 'Party cashout on inactivity',
'time_running_out': 'Time is running out! Play now or your ticket will be withdrawn automatically in 30 sec',
'there_was_error': 'THERE WAS AN ERROR PLEASE TRY AGAIN OR RELOAD',
'detailed_game_rules': 'Detailed Game Rules',
'welcome': 'Welcome to Raccoon Rush - a novel and exciting game!',
'general_rules': 'GENERAL RULES',
'general_rules_p1': '• The multiplier starts at 1 and goes up to ',
'general_rules_p2': '• Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'general_rules_p3': '• If the maximum payout is reached, bet is automatically cashed out at the maximum win amount',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'progressive_jackpot_p1': '1% from each stake is dedicated to a progressive jackpot fund.',
'progressive_jackpot_p2': 'Jackpot is awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known.',
'progressive_jackpot_p3': 'Once the "Must-Hit-By" value is reached, the first player to place a bet afterward will have the chance to win the jackpot. A jackpot symbol will appear on a random lane, and the jackpot is won if the player reaches the lane with the symbol. If the player does not reach the lane with the jackpot symbol due to either cashing out or crashing, the next player to place a bet will see the jackpot symbol appear and have the chance to win. This process repeats until the jackpot is won.',
'showdown': 'SHOWDOWN',
'showdown_p1': 'After the player cashes out, a red square will be displayed at the location where the collision would have occurred if the player had continued skipping lanes.',
'return_to_player': 'RETURN TO PLAYER',
'return_to_player_p1': 'Return to player (RTP), including game returns and jackpots is set at 98% of which 97% comes directly from game RTP with additional 1.00% from jackpot.',
'return_to_player_p2': 'RTP percentages are continuously monitored and verified by independent third parties.',
'disconnection_policy': 'DISCONNECTION POLICY',
'disconnection_policy_p1': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the inactivity from the player reaches ',
'disconnection_policy_p2': 's your bet will be auto cashed out and credits will be applied to your account.',
'network_latency': 'NETWORK LATENCY',
'network_latency_p1': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'sound': 'SOUND',
'sound_p1': 'Raccoon Rush is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through icons for fx (1) and sound (2) located at the bottom right of the screen.',
'error_handling': 'ERROR HANDLING',
'error_handling_p1': 'In event of an error our support team will try to resolve the issue as soon as possible, and resume with regular game. In case of an issue with a game itself, all bets will be cancelled and stake amount returned to players.',
'max_payout': 'Maximum payout is ',
'max_bet_is': 'Max bet is ',
'min_bet_is': 'Min bet is ',
'per_bet': 'per bet.',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'attention_jackpot': 'Attention, Jackpot at lane ',
'lane': 'lane',
'congratulations': 'congratulations',
'you_won': 'you won',
'balance': 'balance',
'time': 'time',
'jackpot': 'jackpot',
'escaped': 'ESCAPED',
'max_win': 'MAX WIN',
'check_internet': 'Please check your internet connection',
'websocket_disconected': 'Websocket disconnected',
'websocket_connection_error': 'Websocket connection error !',
'session_expired': 'Session has expired please reload',
'remaining_bets': 'Remaining Bets',
'stop_autoplay': 'STOP',
'autoplay_and_autocashout': 'AUTOPLAY AND AUTOCASHOUT',
'autoplay_and_autocashout_p1': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'autoplay_and_autocashout_p2': 'An autocashout multiplier can be selected from the game screen, and when set, the game will automatically cash-out if the player is not hit by a car before reaching selected multiplier.',
'autoplay_button_label': 'To begin, choose a lane.',
'min_bet_amount_is': 'Min Bet Amount is',
'bonus_rounds': 'bonus rounds',
'bonus_rounds_p1': 'A player may be randomly awarded with bonus rounds (i.e. free bets). A popup graphic will appear on screen notifying the player about awarded bonus rounds, number of bets and amount per bet.',
'bonus_rounds_p2': 'Once they are awarded player may choose when to start the bonus round. Once the bonus rounds is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment.',
'bonus_rounds_p3': 'Bonus rounds could have a time limit, after which they become void and unusable. The validity period, if any, is displayed beneath the bonus round.',
'bonus_rounds_p4': 'Player idleness may result in the cancellation of an active bonus round. In this case, bonus rounds number is decreased and the player\'s balance stays the same.',
'valid_until': 'valid until',
'bonus': 'bonus',
'rounds': 'rounds',
'win_eur': 'win EUR',
'complete_your_bet': 'Please complete your bet first',
'complete_auto_play': 'Please complete your auto play first',
'yes': 'yes',
'no': 'no',
'play_bonus_rounds': 'Play bonus rounds?',
'bet_history': 'Bet history',
'jackpot_history': 'Jackpot history',
'ticket_id': 'Ticket ID',
'created': 'created time',
'currency': 'currency',
'bet': 'bet',
'bet_eur': 'bet EUR',
'lanes': 'lanes',
'cars': 'cars',
'multiplier': 'multiplier',
'win': 'win',
'no_betting_history': 'No betting history found. Make a bet, and it will appear here.',
'no_jackpot_history': 'No jackpot history available.',
'date': 'date',
'user': 'user'
};
